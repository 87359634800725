import React, {useState} from 'react';
// @mui
import {Box} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useTheme} from "@mui/material/styles";
import Popover from "@mui/material/Popover";
import Stack from "@mui/material/Stack";
import ReactMarkdown from "react-markdown";

import {useAuthContext} from "../../auth/hooks";
import NewsContainer from "./news_container";
import {customScrollbarStyles} from "../../theme/css";
import Iconify from "../iconify";


export default function CompanyValuationAiAnalysis({ticker, valuationAnalysis}: {
  ticker: string | undefined,
  valuationAnalysis: any
}) {
  const {authenticated} = useAuthContext();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handlePopoverOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const theme = useTheme();
  const primaryMain = theme.palette.primary.main;
  const primaryLight = theme.palette.primary.light;
  const errorLight = theme.palette.error.light;
  const errorMain = theme.palette.error.main;
  const grey900 = theme.palette.grey[900];

  const verdictValueToColor = (value: string) => {
    switch (value) {
      case 'Strongly Undervalued':
        return primaryMain;
      case 'Undervalued':
        return primaryLight;
      case 'Fairly Valued':
        return grey900;
      case 'Overvalued':
        return errorLight;
      case 'Strongly Overvalued':
        return errorMain;
      default:
        return grey900;
    }
  }
  const hoverStyles = {
    transition: 'transform 0.3s ease-in-out',
    "&:hover": {
      cursor: "help",
      transform: 'scale(1.005)' // Slightly enlarge on hover
    },
  }


  if (!authenticated) {
    return (
      <Box/>
    );
  }

  if (!valuationAnalysis || !valuationAnalysis.summary) {
    return (
      <Stack sx={{mb: 3, height: '100%'}} spacing={1} direction="column" alignItems="center" justifyContent="center">
        <Stack direction="row" spacing={0.5} alignItems="center">
          <Iconify width={28} icon='icon-park-twotone:attention' sx={{color: 'error.main'}}/>
          <Typography variant="h6" sx={{color: 'text.primary'}}>
            Sentiment Analysis
          </Typography>
        </Stack>
        <Typography variant="body2" sx={{color: 'text.secondary'}}>
          Sentiment analysis is not available for this company.
        </Typography>

      </Stack>
    );
  }

  return (
    <Box
      sx={{
        maxHeight: '890px', // Adjust this value to fit exactly 5 lines of text
        overflowY: 'auto', // Enable vertical scrolling
        ...customScrollbarStyles(theme)
      }}
    >

      <Box
        onMouseEnter={(e) => handlePopoverOpen(e)}
        onMouseLeave={handlePopoverClose}
        sx={{
          ...hoverStyles,
        }}
      >
        <Typography variant="h6"> Sentiment </Typography>
        <Typography variant="body2">
          According to the latest articles, <strong>${ticker}</strong> is: <Typography variant="body1" sx={{
          fontWeight: "bold",
          color: verdictValueToColor(valuationAnalysis.verdict)
        }}>{valuationAnalysis.verdict}</Typography>
        </Typography>
      </Box>
      <Typography variant="body2">
        <ReactMarkdown>{valuationAnalysis?.summary}</ReactMarkdown>
      </Typography>
      <Typography variant="h6"> Articles </Typography>
      {valuationAnalysis?.news && <NewsContainer news={valuationAnalysis?.news}/>}
      <Popover
        id="mouse-over-popover"
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{vertical: 'top', horizontal: 'left'}}
        transformOrigin={{vertical: 'bottom', horizontal: 'left'}}
        onClose={handlePopoverClose}
        disableRestoreFocus
        disableScrollLock
        sx={{pointerEvents: 'none'}}
        slotProps={{
          paper: {style: {width: 250}},
        }}
      >
        <Typography variant="body2" sx={{p: 0.5, fontSize: 12}}>
          Could be one of:
          <br/> - Strongly Undervalued
          <br/> - Undervalued
          <br/> - Fairly Valued
          <br/> - Overvalued
          <br/> - Strongly Overvalued
        </Typography>
      </Popover>
    </Box>
  );
}
