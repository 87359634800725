import React, {useState} from "react";
import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import {KeyboardArrowLeft, KeyboardArrowRight} from "@mui/icons-material";

type SortOrder = "asc" | "desc";

export default function CustomTable({
                                      headers,
                                      rawData,
                                      renderRow,
                                      rowsPerPage = 10,
                                    }: {
  headers: string[];
  rawData: any[];
  renderRow: (row: any) => JSX.Element;
  rowsPerPage?: number;
}) {
  const [sortConfig, setSortConfig] = useState<{ column: string | null; order: SortOrder }>({
    column: null,
    order: "asc",
  });
  const [page, setPage] = useState(0);
  const [sortedData, setSortedData] = useState(rawData);

  const handleSort = (column: string) => {
    const isAsc = sortConfig.column === column && sortConfig.order === "asc";
    const order: SortOrder = isAsc ? "desc" : "asc";
    setSortConfig({column, order});

    const sorted = [...sortedData].sort((a, b) => {
      const aValue = a[column];
      const bValue = b[column];

      if (aValue < bValue) return order === "asc" ? -1 : 1;
      if (aValue > bValue) return order === "asc" ? 1 : -1;
      return 0;
    });
    setSortedData(sorted);
  };

  const paginatedData = sortedData.slice(page * rowsPerPage, (page + 1) * rowsPerPage);

  const renderPagination = () => {
    const totalPages = Math.ceil(sortedData.length / rowsPerPage);
    const pagesToDisplay = [];

    for (let i = 0; i < totalPages; i += 1) {
      if (i < 3 || i === totalPages - 1 || Math.abs(i - page) <= 1) {
        pagesToDisplay.push(i);
      } else if (i === 3 && page > 4) {
        pagesToDisplay.push("...");
      } else if (i > page && i === totalPages - 2 && page < totalPages - 3) {
        pagesToDisplay.push("...");
      }
    }

    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <IconButton
          onClick={() => setPage((prev) => Math.max(0, prev - 1))}
          disabled={page === 0}
        >
          <KeyboardArrowLeft/>
        </IconButton>
        {pagesToDisplay.map((p, index) => (
          <Box
            key={index}
            mx={0.5}
            p={1}
            onClick={() => typeof p === "number" && setPage(p)}
            style={{
              cursor: typeof p === "number" ? "pointer" : "default",
              fontWeight: page === p ? "bold" : "normal",
            }}
          >
            {typeof p === "number" ? p + 1 : p}
          </Box>
        ))}
        <IconButton
          onClick={() => setPage((prev) => Math.min(prev + 1, totalPages - 1))}
          disabled={page === totalPages - 1}
        >
          <KeyboardArrowRight/>
        </IconButton>
      </Box>
    );
  };

  return (
    <TableContainer
      component={Paper}
      style={{
        display: "flex",
        flexDirection: "column",
        height: "900px",
      }}
    >
      <Table style={{flexGrow: 1}}>
        <TableHead>
          <TableRow>
            {headers.map((header) => (
              <TableCell key={header} align="center">
                <TableSortLabel
                  active={sortConfig.column === header}
                  direction={sortConfig.column === header ? sortConfig.order : "asc"}
                  onClick={() => handleSort(header)}
                >
                  {header}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {paginatedData.length > 0 ? (
            paginatedData.map(renderRow)
          ) : (
            <TableRow>
              <TableCell colSpan={headers.length} align="center">
                No data available
              </TableCell>
            </TableRow>
          )}
          {/* Add empty rows */}
          {paginatedData.length < rowsPerPage &&
            [...Array(rowsPerPage - paginatedData.length)].map((_, index) => (
              <TableRow key={`empty-row-${index}`} style={{height: 53}}>
                <TableCell colSpan={headers.length}/>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <Box
        sx={{
          mt: "auto",
          py: 2,
          backgroundColor: "white",
        }}
      >
        {renderPagination()}
      </Box>
    </TableContainer>
  );
}
