import React, {useEffect, useState} from "react";
import {Box, Grid, LinearProgress} from "@mui/material";
import Stack from "@mui/material/Stack";

import CompanyValuationHistoricalChart, {HistoricalData} from "./company-valuation-historical-chart";
import CompanyValuationPeersComparison, {PeersData} from "./company-valuation-peers-comparison";
import CompanyValuationAiAnalysis from "./company-valuation-ai-analysis";
import axios, {endpoints} from "../../utils/axios";
import AiProgressbar from "../utility_components/ai-progressbar";
import CompanyValuationIntrinsicChart from "./company-valuation-intrinsic-chart";
import CompanyValuationRatiosScoring, {RelativeValuationData} from "./company-valuation-ratios-scoring";

interface CompanyDocumentsProps {
  ticker: string | undefined;
  sector: string | undefined;
  currencySymbol: string;
  setAiAnalysesReady?: (key: string) => void | undefined;
}

interface ValuationData {
  historical_ratios: HistoricalData,
  dcf: {
    dcf_price: number;
    stock_price: number;
  },
  peers: PeersData;
  relative_valuation: RelativeValuationData;
}

export default function CompanyValuation({ticker, sector, currencySymbol, setAiAnalysesReady}: CompanyDocumentsProps) {
  const [valuationData, setValuationData] = useState<ValuationData>({
    historical_ratios: {
      priceToEarnings: {mean: 0, historical: []},
      priceToBook: {mean: 0, historical: []},
      priceToSales: {mean: 0, historical: []},
      priceToFreeCashFlows: {mean: 0, historical: []},
      priceEarningsToGrowth: {mean: 0, historical: []}
    },
    dcf: {dcf_price: 0, stock_price: 0},
    peers: {
      priceToEarnings: {mean: 0, peers: []},
      priceToBook: {mean: 0, peers: []},
      priceToSales: {mean: 0, peers: []},
      priceToFreeCashFlows: {mean: 0, peers: []},
      priceEarningsToGrowth: {mean: 0, peers: []}
    },
    relative_valuation: {
      price_to_earnings: {
        value: 0,
        vh_score: 0,
        relative_score: 0,
        relative_percentage: 0,
        total_companies: 0
      },
      price_to_book: {
        value: 0,
        vh_score: 0,
        relative_score: 0,
        relative_percentage: 0,
        total_companies: 0
      },
      price_to_free_cashflow: {
        value: 0,
        vh_score: 0,
        relative_score: 0,
        relative_percentage: 0,
        total_companies: 0
      },
      ev_to_ebitda: {
        value: 0,
        vh_score: 0,
        relative_score: 0,
        relative_percentage: 0,
        total_companies: 0
      },
      peg: {
        value: 0,
        vh_score: 0,
        relative_score: 0,
        relative_percentage: 0,
        total_companies: 0
      }
    }
  });
  const [valuationAnalysis, setValuationAnalysis] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingAnalysis, setIsLoadingAnalysis] = useState(false);

  useEffect(() => {
    const getCompanyValuation = async () => {
      try {
        setIsLoading(true);
        const valuationMetricsResponse = await axios.get<any>(`/api/companies/valuation/v2/${ticker}`);
        setValuationData(valuationMetricsResponse.data);
      } catch (error) {
        console.error("Error fetching company valuation:", error);
      }
      setIsLoading(false);
    }

    const getCompanyAiAnalysis = async () => {
      try {
        setIsLoadingAnalysis(true);
        const valuationAnalysisResponse = await axios.post<any>(`${endpoints.ai_analysis}/${ticker}?analysis_type=valuation`);
        setValuationAnalysis(JSON.parse(valuationAnalysisResponse.data.answer));
      } catch (error) {
        console.error("Error fetching company valuation AI analysis:", error);
      } finally {
        if (setAiAnalysesReady) {
          setAiAnalysesReady('valuation');
        }
        setIsLoadingAnalysis(false);
      }
    }

    getCompanyValuation();
    getCompanyAiAnalysis();
  }, [ticker, setAiAnalysesReady]);

  if (isLoading) {
    return <LinearProgress/>;
  }
  return (
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} xl={6} sx={{mb: 1}}>
          {valuationData.dcf && (
            <CompanyValuationIntrinsicChart ticker={ticker || ""} dcfData={valuationData.dcf} currencySymbol={currencySymbol}/>
          )}
        </Grid>
        <Grid item xs={12} md={6} xl={6} sx={{mb: 1}}>
          <CompanyValuationRatiosScoring sector={sector} relativeValuation={valuationData.relative_valuation}/>
        </Grid>
        <Grid item xs={12} md={6} xl={6} sx={{mb: 1}}>
          {isLoadingAnalysis && <AiProgressbar/>}
          {!isLoadingAnalysis && ( <CompanyValuationAiAnalysis ticker={ticker} valuationAnalysis={valuationAnalysis}/>)
          }
        </Grid>
        <Grid item xs={12} md={6} xl={6} sx={{mb: 1}}>
          <Stack direction="column">
            {valuationData.historical_ratios && (
              <Box sx={{mb: 2}}>
                <CompanyValuationHistoricalChart
                  priceToEarnings={valuationData.historical_ratios.priceToEarnings}
                  priceToBook={valuationData.historical_ratios.priceToBook}
                  priceToSales={valuationData.historical_ratios.priceToSales}
                  priceToFreeCashFlows={valuationData.historical_ratios.priceToFreeCashFlows}
                  priceEarningsToGrowth={valuationData.historical_ratios.priceEarningsToGrowth}
                />
              </Box>
            )}

            {valuationData?.peers && valuationData.peers?.priceToEarnings?.peers && (
              <Box>
                <CompanyValuationPeersComparison ticker={ticker} peersData={valuationData.peers}/>
              </Box>
            )}
          </Stack>
        </Grid>
      </Grid>
  );
}
