import React from "react";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ReactMarkdown from "react-markdown";

import Iconify from "../iconify";
import AiProgressbar from "../utility_components/ai-progressbar";
import Label from "../label";

interface CompanyDocumentsProps {
  aiAnalysis: AIBusinessAnalysisType;
}

interface AIBusinessAnalysisType {
  business_assessment: {
    business_model_explanation: string,
    pros: string[],
    cons: string[],
  }
  operational_metrics: {
    metrics: {
      metric_name: string,
      metric_performance_and_insight: string,
      verdict: "improved" | "declined" | "same"
    }[]
  }
}

export default function CompanyBusinessAiAnalysis({aiAnalysis}: CompanyDocumentsProps) {
  if (!aiAnalysis.business_assessment) {
    return <AiProgressbar/>;
  }

  const getLabelElement = (verdict: string) => {
    switch (verdict) {
      case "improved":
        return <Label color="success"> Improved </Label>
      case "declined":
        return <Label color="error"> Declined </ Label>
      default:
        return <Label color="info"> Same </ Label>
    }
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Stack direction="row" spacing={0.8} alignItems="center">
              <Iconify
                width={24}
                icon='icons8:idea'
                sx={{
                  color: 'success.main',
                }}/>
              <Typography variant="h6" sx={{color: 'text.primary'}}>
                Business Model
              </Typography>
            </Stack>

            <Typography variant="body2" sx={{
              color: 'text.primary',
            }}>
              <ReactMarkdown>
                {aiAnalysis?.business_assessment.business_model_explanation}
              </ReactMarkdown>
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Stack direction="column" spacing={0.5} sx={{mt: 1, backdropFilter: 'blur(10px)'}}>
          <Typography variant="h6" sx={{color: 'text.primary'}}>
            Strengths
          </Typography>
          {aiAnalysis?.business_assessment.pros.map((text, index) => (
            <Box key={index}>
              <Stack direction="row" spacing={0.8}>
                <Box component="span" sx={{color: 'text.secondary'}}>
                  <Iconify
                    width={24}
                    icon='icon-park-twotone:success'
                    sx={{
                      color: 'success.main',
                    }}/>
                </Box>
                <Box component="span" sx={{color: 'text.secondary'}}>
                  <Typography variant="body2" sx={{
                    color: 'text.secondary',
                  }}>
                    {text}
                  </Typography>
                </Box>
              </Stack>
            </Box>
          ))}
        </Stack>
        <Stack direction="column" spacing={0.5} sx={{mt: 1}}>
          <Typography variant="h6" sx={{color: 'text.primary'}}>
            Concerns
          </Typography>
          {aiAnalysis?.business_assessment.cons.map((text, index) => (
            <Box key={index}>
              <Stack direction="row" spacing={0.8}>
                <Box component="span" sx={{color: 'text.secondary'}}>
                  <Iconify
                    width={24}
                    icon='ic:twotone-error'
                    sx={{
                      color: 'error.main',
                    }}/>
                </Box>
                <Box component="span" sx={{color: 'text.secondary'}}>
                  <Typography variant="body2" sx={{
                    color: 'text.secondary',
                  }}>
                    {text}
                  </Typography>
                </Box>
              </Stack>
            </Box>
          ))}
        </Stack>
      </Grid>

      <Grid item xs={12}>
        <Stack direction="row" spacing={0.8} alignItems="center" sx={{mt: 3}}>
          <Iconify width={24} icon='icons8:idea' sx={{color: 'success.main'}}/>
          <Typography variant="h6" sx={{color: 'text.primary'}}>
            Operational Insights
          </Typography>
        </Stack>
        <Stack direction="column" sx={{mt: 2}}>
          {aiAnalysis?.operational_metrics.metrics.map((metric, index) => (
            <Box key={`metric-${index}`}>
              <Stack direction="row" spacing={0.8}>
                {getLabelElement(metric.verdict)}
                <Typography variant="body2" fontWeight="bold">
                  {metric.metric_name}
                </Typography>
              </Stack>
              <Typography variant="body2">
                <ReactMarkdown>
                  {metric.metric_performance_and_insight}
                </ReactMarkdown>
              </Typography>
            </Box>
          ))}
        </Stack>
      </Grid>
    </Grid>
  );
}
