import React from 'react';
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {useTheme} from "@mui/material/styles";
import Iconify from '../iconify';
import {RisksType} from './types';
import EarningsQuote from "./earnings_quote";
import ExpandableTitle from "./expandable_title";
import Label from "../label";

export default function RenderRisks({risks, paragraphs, onQuoteClick}: {
  risks: RisksType
  paragraphs: string[]
  onQuoteClick: (quote: string) => void
}) {
  const theme = useTheme();

  const getLabelElement = (outlook: string) => {
    switch (outlook) {
      case "low":
        return <Label color="info"> Low </Label>
      case "medium":
        return <Label color="warning"> Medium </ Label>
      case "high":
        return <Label color="error"> High </ Label>
      default:
        return <Label color="warning"> Medium </ Label>
    }
  }

  const title = (
    <Stack direction="row" spacing={0.5}>
      <Iconify icon="material-symbols:warning" sx={{color: theme.palette.warning.light}} width={30}/>
      <Typography variant="h6">{risks.question.question}</Typography>
    </Stack>
  );

  return (
    <Box sx={{mt: 3}}>
      <ExpandableTitle title={title}>
        {risks.answer.risks.sort((a, b) => {
          const sentimentOrder = {"high": 1, "medium": 2, "low": 3};
          return sentimentOrder[a.severity] - sentimentOrder[b.severity];
        }).map((risk, index) => {
          // Check if the quote is found in the document paragraphs
          const isQuoteInDocument = paragraphs.some(paragraph => paragraph.toLowerCase().includes(risk.quote_from_document?.slice(0, -1).toLowerCase()));

          return (
            <React.Fragment key={index}>
              <Stack direction="row" spacing={0.5} sx={{ml: 1, mt: 1}}>
                <Box sx={{minWidth: "63px"}}>
                  {getLabelElement(risk.severity)}
                </Box>
                <Typography variant="body2" fontWeight={700}>{risk.risk}</Typography>
                <EarningsQuote
                  quote={risk.quote_from_document}
                  onQuoteClick={onQuoteClick}
                  isQuoteInDocument={isQuoteInDocument} // Pass the flag here
                />
              </Stack>
              <Stack direction="row" spacing={0.5} sx={{ml: 1}}>
                <Typography variant="body2"><i>Mitigation Strategy:</i></Typography>
                <Typography variant="body2">{risk.mitigation_strategy}</Typography>
              </Stack>
            </React.Fragment>
          );
        })}
      </ExpandableTitle>
    </Box>
  );
};
