import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Link from "@mui/material/Link";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import React from "react";

export default function HowDCFWorks() {

  return (
      <Stack direction="column" spacing={2} alignItems="center" justifyContent="center" sx={{m: 5}}>
        <Box sx={{maxWidth: "600px"}}>
          <Box sx={{color: "text.primary"}}>
            <Typography variant="h6" gutterBottom>
              How does the Discounted Cash Flow (DCF) model work?
            </Typography>
            <Typography variant="body2">
              The DCF model helps value investors determine the intrinsic value of a stock. It estimates the value of a company
              based on the cash it is expected to generate in the future, adjusted for the time value of money. Our model specifically uses the <strong>Levered DCF</strong> approach.
            </Typography>

            <Box height={10}/>

            {/* Importance of Levered DCF */}
            <Typography variant="h6" gutterBottom>
              Why Levered DCF?
            </Typography>
            <Typography variant="body2">
              When valuing a company, it is important to consider the company&apos;s debt levels. Debt can significantly impact a company&apos;s financial performance and value.
              Our <strong>Levered DCF</strong> model accounts for debt by discounting the company&apos;s expected future cash flows to their present value <strong>after taxes and debt payments</strong>.
              This approach provides a more accurate picture of the company&apos;s true value for equity holders.
            </Typography>

            <Box height={10}/>

            {/* 1 */}
            <Typography variant="h6" gutterBottom>
              1. Inputs: What do we need?
            </Typography>
            <Typography variant="body2">
              To use the Levered DCF model, we need the following data:
              <List>
                <ListItem><strong>Free Cash Flow (FCF):</strong> The cash the company generates after accounting for operating expenses, taxes, and debt payments.</ListItem>
                <ListItem><strong>Weighted Average Cost of Capital (WACC):</strong> The rate to discount future cash flows, reflecting the company&apos;s cost of equity and debt.</ListItem>
                <ListItem><strong>Long-Term Growth Rate:</strong> The expected growth rate of cash flows after the projection period.</ListItem>
                <ListItem><strong>Net Debt:</strong> The company&apos;s total debt minus its cash.</ListItem>
                <ListItem><strong>Shares Outstanding:</strong> Total number of company shares.</ListItem>
              </List>
            </Typography>

            <Box height={10}/>

            {/* 2 */}
            <Typography variant="h6" gutterBottom>
              2. Steps in the calculation
            </Typography>
            <Typography variant="body2">
              <strong>Step 1: Discount Future Cash Flows</strong><br/>
              Future free cash flows are discounted back to today&apos;s value using the WACC.
              <br/>
              Formula:
              <br/>
              <em>Present Value of FCF = FCF for Year n / (1 + WACC)<sup>Years to Discount</sup></em>
            </Typography>
            <Typography variant="body2">
              <strong>Step 2: Calculate Terminal Value</strong><br/>
              This estimates the value of all cash flows beyond the projection period.
              <br/>
              Formula:
              <br/>
              <em>Terminal Value = Last Year&apos;s FCF × (1 + Long-Term Growth Rate) / (WACC - Long-Term Growth Rate)</em>
            </Typography>
            <Typography variant="body2">
              <strong>Step 3: Add Up the Values</strong><br/>
              Add the discounted cash flows and the terminal value to get the <strong>Enterprise Value</strong> (the value of the entire company).
            </Typography>
            <Typography variant="body2">
              <strong>Step 4: Subtract Net Debt</strong><br/>
              Subtract the company&apos;s net debt to calculate the <strong>Equity Value</strong> (the value for shareholders).
            </Typography>
            <Typography variant="body2">
              <strong>Step 5: Divide by Shares Outstanding</strong><br/>
              Divide the equity value by the number of shares to get the <strong>Intrinsic Price Per Share</strong>.
            </Typography>

            <Box height={10}/>

            {/* 3 */}
            <Typography variant="h6" gutterBottom>
              3. Result: The Intrinsic Price
            </Typography>
            <Typography variant="body2">
              The Levered DCF model provides the intrinsic price per share of the company&apos;s stock. If this price is higher
              than the current stock price, the stock may be undervalued (a potential buy).
            </Typography>

            <Box height={10}/>

            {/* 4 */}
            <Typography variant="h6" gutterBottom>
              4. Key Considerations
            </Typography>
            <Typography variant="body2">
              <List>
                <ListItem><strong>Accuracy of Inputs:</strong> The result depends heavily on accurate inputs (e.g., FCF, WACC, growth rate).</ListItem>
                <ListItem><strong>Realistic Growth Rates:</strong> The long-term growth rate must be lower than WACC; otherwise, the formula won&apos;t work.</ListItem>
                <ListItem><strong>Debt Impacts:</strong> Because we account for debt, companies with high debt levels will have lower intrinsic values.</ListItem>
                <ListItem><strong>Negative or Zero Cash Flow:</strong> If cash flow is consistently negative or zero, the model won&apos;t provide a meaningful result.</ListItem>
              </List>
            </Typography>

            <Box height={10}/>

            {/* Example */}
            <Typography variant="h6" gutterBottom>
              Example
            </Typography>
            <Typography variant="body2">
              Let&apos;s say a company has:
              <List>
                <ListItem><strong>FCFs:</strong> $100M (Year 1), $110M (Year 2), $120M (Year 3)</ListItem>
                <ListItem><strong>WACC:</strong> 10%</ListItem>
                <ListItem><strong>Long-Term Growth Rate:</strong> 3%</ListItem>
                <ListItem><strong>Net Debt:</strong> $50M</ListItem>
                <ListItem><strong>Shares Outstanding:</strong> 10M</ListItem>
              </List>
              Using the Levered DCF model, the intrinsic price might calculate to $15.50 per share. If the stock is currently trading at $10, it might be undervalued.
            </Typography>

            <Divider sx={{my: 2}}/>
            <Box sx={{fontSize: 14}}>
              If you have any feedback, questions, or suggestions on the Levered DCF model, we would love to hear them!
            </Box>
            <Box sx={{fontSize: 13}}>
              Drop me a line at <Link sx={{cursor: "pointer"}}>contact@valuehunter.net</Link>
            </Box>
          </Box>
        </Box>
      </Stack>
  );
}
