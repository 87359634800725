import {Box, Card, Grid} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Popover from "@mui/material/Popover";
import React, {useState} from "react";
import AiProgressbar from "../utility_components/ai-progressbar";
import SignalIndicatorComponent from "./one_min_signal_indicator";
import {ScoreData} from "./score-data";
import {ScoreData as ScoreDataType} from "./types";
import Iconify from "../iconify";
import {getProgressBarColor, getUserType, UserTypes} from "./utils";
import {useAuthContext} from "../../auth/hooks";
import {BecomePremiumToAccessDialog} from "../utility_components/become-premium-to-access-dialog";


interface OneMinAnalysisProps {
  ticker: string | undefined;
  overallScore: number;
  radarChartData: { series: { data: number[]; name: string }[]; categories: (string | string[])[] };
  scoreData: ScoreDataType;
  isAnalysisLoading: boolean;
  oneMinAnalysis: {
    verdict: "strong buy" | "buy" | "hold" | "sell" | "strong sell";
    good_investment_reasons: string[];
    bad_investment_reasons: string[];
  };
}


export default function OneMinAnalysis({
                                         ticker,
                                         oneMinAnalysis,
                                         isAnalysisLoading,
                                         overallScore,
                                         radarChartData,
                                         scoreData
                                       }: OneMinAnalysisProps) {
  const [dialogContent, setDialogContent] = useState('');
  const {authenticated, user} = useAuthContext();

  const [dialogOpen, setDialogOpen] = useState(false);
  const [premiumDialogOpen, setPremiumDialogOpen] = useState(false);

  const fundamentalRecommendationText = '<strong>How Fundamental Recommendation works</strong>? <br/> Our algorithm analyzes the company based on <strong>numerical data and fundamentals</strong>, taking into account factors like valuation, financial strength, efficiency, and growth potential.<br/> Based on this it generates a long-term recommendation from value investing point of view <strong>(buy, hold, sell)</strong>.';
  const aiRecommendationText = '<strong>How AI Recommendation works</strong>? <br/> The AI analyzes and weights in both <strong>fundamental data and qualitative textual data</strong> like: <br/> - annual and quarterly reports <br/> - earnings calls <br/> - press releases <br/> - latest news <br/> - fundamental data and ratios <br/> - and others <br/><br/>  based on that it generates a long-term recommendation  from value investing point of view <strong>(buy, hold, sell)</strong>.';
  const disclaimerText = '<strong><i>Disclaimer:</i></strong> This recommendation is not a financial advice.';
  const goodInvestmentText = 'The AI analyzes all <strong>available data</strong> and tries to find <strong>specific reasons</strong> why this company might be a <strong>good</strong> investment and on the contrary, why it might be a <strong>bad</strong> investment. <br/> You should interpret this information and decide for yourself with which reasons you agree or disagree.';

  const handleOpen = () => {
    setDialogOpen(true);
  }

  const handleClose = () => {
    setDialogOpen(false);
  }

  // overall score > 4.3 -> strong buy
  // 3.7 < overall score <= 4.3 -> buy
  // 2.7 < overall score <= 3.7 -> hold
  // 1.5 < overall score <= 2.7 -> sell
  // overall score < 1.5 -> strong sell
  let quantitativeSignal = "hold";
  if (overallScore > 4.3) {
    quantitativeSignal = "strong buy";
  } else if (overallScore > 3.7) {
    quantitativeSignal = "buy";
  } else if (overallScore > 2.7) {
    quantitativeSignal = "hold";
  } else if (overallScore > 1.5) {
    quantitativeSignal = "sell";
  } else if (overallScore <= 1.5) {
    quantitativeSignal = "strong sell";
  }

  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverContent, setPopoverContent] = useState('');
  const open = Boolean(anchorEl);
  const handlePopoverOpen = (event: any, popContent: string) => {
    setAnchorEl(event.currentTarget);
    setPopoverContent(popContent);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setPopoverContent('');
  };
  const hoverStyles = {
    '&:hover': {
      cursor: 'help',
    },
  };

  const userType = getUserType(user, ticker);
  const renderBlurred = (!authenticated || (userType === UserTypes.PremiumNoCredits || userType === UserTypes.FreeNoCredits));

  const renderBlurredRecommendation = () => (
    <Stack direction="column" justifyContent="center" alignItems="center"
           sx={{mt: 1, width: '100%', filter: 'blur(5px)', cursor: "pointer"}}
           onClick={() => setPremiumDialogOpen(true)}>
      <SignalIndicatorComponent signal="hold"/>
    </Stack>);

  const renderBlurredReasons = (good: boolean) => (
    // 3 reasons
    <Stack direction="column" justifyContent="center" alignItems="center"
           sx={{mt: 1, width: '100%', filter: 'blur(5px)', cursor: "pointer"}}
           onClick={() => setPremiumDialogOpen(true)}>
      {Array.from({length: 3}).map((_, index) => (
        <Box key={index} sx={{my: 0.5}}>
          <Stack direction="row" spacing={0.8}>
            <Box component="span" sx={{color: 'text.secondary'}}>
              <Iconify
                width={24}
                icon='icon-park-twotone:check-one'
                sx={{
                  color: good ? 'success.main' : 'error.main',
                }}/>
            </Box>
            <Box component="span" sx={{color: 'text.secondary'}}>
              <Typography variant="body2" sx={{
                color: 'text.secondary',
              }}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla quis lorem ut libero malesuada feugiat
              </Typography>
            </Box>
          </Stack>
        </Box>
      ))}
    </Stack>
  );

  const renderRecommendationCard = () => (
    <Card sx={{height: '100%', width: '100%', p: 3}}>
      <Stack direction="row" spacing={1}>
        <Typography variant="h6">ValueHunter Recommendation</Typography>
        <Iconify icon="mdi:comment-help" sx={{cursor: "pointer"}} width={27} onClick={() => {
          handleOpen();
          setDialogContent(`${fundamentalRecommendationText}<br/><br/>${aiRecommendationText} <br/><br/> ${disclaimerText}`)
        }}/>
      </Stack>
      <Stack direction="column" alignItems="center" justifyContent="center" spacing={6}
             sx={{my: 2, height: '100%'}}>
        <Box
          onMouseEnter={(e) => handlePopoverOpen(e, `${fundamentalRecommendationText}<br/><br/>${disclaimerText}`)}
          onMouseLeave={handlePopoverClose}
          sx={{mb: 2, ...hoverStyles}}
        >
          <Stack direction="row" spacing={1}
                 justifyContent="center"
                 alignItems="center"
          >
            <Typography variant="subtitle2">Fundamental Recommendation</Typography>
            <Box component="span">
              <Iconify icon="tabler:math" width={27}/>
            </Box>
          </Stack>

          <Stack direction="column" justifyContent="center" alignItems="center" sx={{mt: 1, width: '100%'}}>
            {/* @ts-ignore */}
            {!renderBlurred && !isAnalysisLoading && <SignalIndicatorComponent signal={quantitativeSignal}/>}
            {renderBlurred && !isAnalysisLoading && renderBlurredRecommendation()}

          </Stack>

        </Box>
        <Box
          onMouseEnter={(e) => handlePopoverOpen(e, `${aiRecommendationText}<br/><br/>${disclaimerText}`)}
          onMouseLeave={handlePopoverClose}
          sx={{mb: 2, ...hoverStyles}}
        >
          <Stack direction="row" spacing={1}
                 justifyContent="center"
                 alignItems="center">
            <Typography variant="subtitle2">AI Recommendation</Typography>
            <Box component="span">
              <Iconify icon="mingcute:ai-line" width={27}/>
            </Box>
          </Stack>
          {renderBlurred && !isAnalysisLoading && renderBlurredRecommendation()}
          {isAnalysisLoading && <AiProgressbar/>}
          {!isAnalysisLoading && !renderBlurred && oneMinAnalysis?.verdict &&
            (<Stack direction="column" justifyContent="center" alignItems="center" sx={{mt: 1, width: '100%'}}>
              <SignalIndicatorComponent signal={oneMinAnalysis.verdict}/>
            </Stack>)
          }
        </Box>
      </Stack>
    </Card>
  )

  const renderReasonsCard = () => (

    <Card sx={{height: '100%', width: '100%'}}>
      <Box sx={{p: 3}}>
        <Box className="reasons">
          <Stack direction="row" spacing={1}>
            <Typography variant="h6" className="reasons-title">Is {ticker} a good investment right
              now</Typography>
            <Iconify icon="mdi:comment-help" sx={{cursor: "pointer"}} width={27} onClick={() => {
              handleOpen();
              setDialogContent(`${goodInvestmentText}<br/><br/>${disclaimerText}`)
            }}/>
          </Stack>

          {isAnalysisLoading && <AiProgressbar/>}
          {!isAnalysisLoading && renderBlurred && (
            <>
              <Box className="good-reasons">
                <Typography variant="subtitle1" className="reasons-title" sx={{my: 1}}>Might be
                  a <strong>good</strong> investment because:</Typography>
                {renderBlurredReasons(true)}
              </Box>
              <Box className="bad-reasons">
                <Typography variant="subtitle1" className="reasons-title" sx={{my: 1}}>Might be
                  a <strong>bad</strong> investment because:</Typography>
                {renderBlurredReasons(false)}
              </Box>
            </>
          )}
          {oneMinAnalysis && !renderBlurred && oneMinAnalysis.good_investment_reasons && oneMinAnalysis.bad_investment_reasons && (
            <>
              <Box className="good-reasons" sx={{mt: 2}}>
                <Typography variant="subtitle1" className="reasons-title" sx={{my: 1}}>Might be
                  a <strong>good</strong> investment because:</Typography>
                {oneMinAnalysis.good_investment_reasons.map((reason, index) => (
                  <Box key={index}>
                    <Stack direction="row" spacing={0.8}>
                      <Box component="span" sx={{color: 'text.secondary'}}>
                        <Iconify
                          width={24}
                          icon='icon-park-twotone:check-one'
                          sx={{
                            color: 'success.main',
                          }}/>
                      </Box>
                      <Box component="span" sx={{color: 'text.secondary'}}>
                        <Typography variant="body2" sx={{
                          color: 'text.secondary',
                        }}>
                          {reason}
                        </Typography>
                      </Box>
                    </Stack>
                  </Box>
                ))}
              </Box>
              <Box className="bad-reasons" sx={{mt: 2}}>
                <Typography variant="subtitle1" className="reasons-title" sx={{my: 1}}>Might be
                  a <strong>bad</strong> investment because:</Typography>
                {oneMinAnalysis.bad_investment_reasons.map((reason, index) => (
                  <Box key={index}>
                    <Stack direction="row" spacing={0.8}>
                      <Box component="span" sx={{color: 'text.secondary'}}>
                        <Iconify
                          width={24}
                          icon='icon-park-twotone:attention'
                          sx={{
                            color: 'error.main',
                          }}/>
                      </Box>
                      <Box component="span" sx={{color: 'text.secondary'}}>
                        <Typography variant="body2" sx={{
                          color: 'text.secondary',
                        }}>
                          {reason}
                        </Typography>
                      </Box>
                    </Stack>
                  </Box>
                ))}
              </Box>

            </>
          )}
        </Box>
      </Box>
    </Card>
  );

  return (
    <Box className="one-min-analysis">
      <Stack id="one-min-analysis" direction="row" spacing={1} alignItems="center" sx={{mb: 1}}>
        <Iconify icon="hugeicons:timer-02" width={30}/>
        <Typography variant="h4" className="section-title">1-minute Analysis</Typography>
      </Stack>
      <Stack className="one-min-analysis-content" spacing={2}>
        {/* ======== Scoring ======== */}
        {/* @ts-ignore */}
        <ScoreData overallScore={overallScore} chart={radarChartData} scoreData={scoreData}/>

        {/* ======== AI Recommendation and Analysis ======== */}
        <Grid container spacing={2}>
          <Grid item xs={12} lg={4} xl={4}>
            {renderRecommendationCard()}
          </Grid>
          <Grid item xs={12} lg={8} xl={8}>
            <Stack className="one-min-analysis-reasons" direction="column" spacing={2} sx={{height: '100%'}}>
              {renderReasonsCard()}
            </Stack>
          </Grid>
        </Grid>
        <Popover
          id="mouse-over-popover"
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{vertical: 'top', horizontal: 'center'}}
          transformOrigin={{vertical: 'bottom', horizontal: 'center'}}
          onClose={handlePopoverClose}
          disableRestoreFocus
          disableScrollLock
          sx={{pointerEvents: 'none'}}
          slotProps={{
            paper: {style: {width: 250}},
          }}
        >
          <Typography variant="body2" sx={{p: 0.5, fontSize: 13}} dangerouslySetInnerHTML={{__html: popoverContent}}/>
        </Popover>
        <Dialog open={dialogOpen} onClose={handleClose}>
          <DialogTitle>How this works?</DialogTitle>
          <DialogContent>
            <Typography variant="body2" sx={{p: 0.5}} dangerouslySetInnerHTML={{__html: dialogContent}}/>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>
        </Dialog>
        <BecomePremiumToAccessDialog open={premiumDialogOpen} onClose={() => setPremiumDialogOpen(false)}
                                     ticker={ticker}/>
      </Stack>
    </Box>
  )
}
