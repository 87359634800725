import React from 'react';
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {useTheme} from "@mui/material/styles";
import Iconify from '../iconify';
import {RevenuePerformanceAnswerType} from './types';
import EarningsQuote from "./earnings_quote";
import ExpandableTitle from './expandable_title';

export default function RenderRevenuePerformance({revenuePerformance, paragraphs, onQuoteClick}: {
  revenuePerformance: RevenuePerformanceAnswerType
  paragraphs: string[] // Add the paragraphs to check for quote existence
  onQuoteClick: (quote: string) => void
}) {
  const theme = useTheme();
  const title = (
    <Stack direction="row" spacing={0.5}>
      <Iconify icon="hugeicons:money-bag-02" sx={{color: theme.palette.primary.main}} width={30}/>
      <Typography variant="h6">{revenuePerformance.question.question}</Typography>
    </Stack>
  );

  return (
    <Box sx={{mt: 3}}>
      <ExpandableTitle initialExpanded title={title}>
        {revenuePerformance.answer.metrics.map((metric, index) => {
          // Check if the quote is found in the document paragraphs
          const isQuoteInDocument = paragraphs.some(paragraph => paragraph.toLowerCase().includes(metric.quote_from_document?.slice(0, -1).toLowerCase()));

          return (
            <React.Fragment key={index}>
              <Typography variant="body2" fontWeight={700} sx={{mt: 1}}>{metric.metric}:</Typography>
              <Stack direction="row" spacing={0.5}>
                <Box sx={{minWidth: "15px"}}>
                  {metric.performance_verdict === "increased" &&
                    <Iconify icon="mdi:arrow-up-bold" sx={{color: theme.palette.success.main}}/>}
                  {metric.performance_verdict === "decreased" &&
                    <Iconify icon="mdi:arrow-down-bold" sx={{color: theme.palette.error.main}}/>}
                  {(metric.performance_verdict === "same" || metric.performance_verdict === "n/a") &&
                    <Iconify icon="mdi:arrow-right-bold" sx={{color: theme.palette.warning.main}}/>}
                </Box>
                <Typography variant="body2">{metric.performance_overview}</Typography>

                {/* Pass the isQuoteInDocument flag to EarningsQuote */}
                <EarningsQuote
                  quote={metric.quote_from_document}
                  onQuoteClick={onQuoteClick}
                  isQuoteInDocument={isQuoteInDocument} // Pass the flag here
                />
              </Stack>
              {/*
              <Typography variant="body2">Reason: {metric.performance_reason}</Typography>
              */}
            </React.Fragment>
          );
        })}
      </ExpandableTitle>
    </Box>
  );
};
