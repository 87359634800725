export const enlargeOnHover = {
  transition: 'transform 0.1s ease-in-out',
  "&:hover": {
    transform: 'scale(1.05)' // Slightly enlarge on hover
  },
}

export const blurredItem = {
  filter: 'blur(5px)'
}

export const blurredItemPointer = {
  filter: 'blur(5px)',
  cursor: 'pointer'
}
