import React, {useState} from 'react';
// @mui
import {Card, CardContent} from "@mui/material";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Popover from "@mui/material/Popover";

import Iconify from "../iconify";
import {fShortenNumber} from "../../utils/format-number";
import {blurredItemPointer} from "../../common/styles";
import {BecomePremiumToAccessDialog} from "../utility_components/become-premium-to-access-dialog";

export interface InsiderTradingType {
  trades: {
    symbol: string,
    filingDate: string,
    transactionDate: string,
    reportingCik: string,
    transactionType: string,
    securitiesOwned: number,
    companyCik: string,
    reportingName: string,
    typeOfOwner: string,
    acquisitionOrDisposition: string,
    formType: string,
    securitiesTransacted: number,
    price: number,
    securityName: string,
    link: string
  }[]
}

export default function CompanyInsiderTradingCard({
                                                    ticker,
                                                    color,
                                                    icon,
                                                    title1,
                                                    value1,
                                                    help1,
                                                    value1_prefix,
                                                    title2,
                                                    value2,
                                                    value2_prefix,
                                                    help2,
                                                    subtitle2,
                                                    blurred
                                                  }: {
  ticker: string,
  color: string,
  icon: string,
  title1: string,
  value1: number,
  value1_prefix?: string,
  help1?: string,
  title2: string,
  value2: number,
  value2_prefix?: string,
  help2?: string,
  // optional
  subtitle2?: string | ""
  blurred?: boolean
}) {
  const [premiumDialogOpen, setPremiumDialogOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverContent, setPopoverContent] = useState('');
  const open = Boolean(anchorEl);
  const handlePopoverOpen = (event: any, popContent: string) => {
    setAnchorEl(event.currentTarget);
    setPopoverContent(popContent);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setPopoverContent('');
  };
  const hoverStyles = {
    '&:hover': {
      cursor: 'help',
    },
  };

  return (
    <Card sx={{width: "100%"}}>
      <CardContent>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Stack direction="column">
            <Stack direction="row" spacing={1}>
              <Typography variant="subtitle2">{title1}</Typography>
              {help1 && <Iconify icon="bi:question-circle" color="text.secondary" width={16}
                                 onMouseEnter={(e) => handlePopoverOpen(e, help1)}
                                 onMouseLeave={handlePopoverClose}
                                 sx={{...hoverStyles}}
              />}
            </Stack>
            <Typography variant="h6" color={color} sx={blurred ? blurredItemPointer : {}}
                        onClick={blurred ? () => setPremiumDialogOpen(true) : undefined}
                        gutterBottom>{value1_prefix}{fShortenNumber(value1)}</Typography>

            <Stack direction="row" spacing={1}>
            <Typography variant="subtitle2">{title2}</Typography>
            {help2 && <Iconify icon="bi:question-circle" color="text.secondary" width={16}
                               onMouseEnter={(e) => handlePopoverOpen(e, help2)}
                               onMouseLeave={handlePopoverClose}
                               sx={{...hoverStyles}}/>}
            </Stack>
            <Typography variant="h6" color={color} sx={blurred ? blurredItemPointer : {}}
                        onClick={blurred ? () => setPremiumDialogOpen(true) : undefined}
            >{value2_prefix}{fShortenNumber(value2)}</Typography>
            <Typography variant="body2" color="text.secondary" sx={blurred ? blurredItemPointer : {}}
                        onClick={blurred ? () => setPremiumDialogOpen(true) : undefined}

            >{subtitle2}</Typography>
          </Stack>
          <Iconify
            icon={icon}
            color={color}
            sx={{
              width: 90,
              height: 90,
              opacity: 0.08,
            }}/>
        </Stack>

      </CardContent>
      <BecomePremiumToAccessDialog open={premiumDialogOpen} onClose={() => setPremiumDialogOpen(false)}
                                   ticker={ticker}/>
      <Popover
        id="mouse-over-popover"
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{vertical: 'top', horizontal: 'center'}}
        transformOrigin={{vertical: 'bottom', horizontal: 'center'}}
        onClose={handlePopoverClose}
        disableRestoreFocus
        disableScrollLock
        sx={{pointerEvents: 'none'}}
        slotProps={{
          paper: {style: {width: 250}},
        }}
      >
        <Typography variant="body2" sx={{p: 0.5, fontSize: 13}} dangerouslySetInnerHTML={{__html: popoverContent}}/>
      </Popover>


    </Card>


  );
}
