import React from 'react';
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {useTheme} from "@mui/material/styles";
import Iconify from '../iconify';
import {GuidanceType} from './types';
import EarningsQuote from "./earnings_quote";
import ExpandableTitle from "./expandable_title";
import Label from "../label"; // Assuming types are extracted into a common file

export default function RenderGuidance({futurePerformance, paragraphs, onQuoteClick}: {
  futurePerformance: GuidanceType
  paragraphs: string[]
  onQuoteClick: (quote: string) => void
}) {
  const theme = useTheme();

  const getLabelElement = (outlook: string) => {
    switch (outlook) {
      case "positive":
        return <Label color="success"> Positive </Label>
      case "negative":
        return <Label color="error"> Negative </ Label>
      case "neutral":
        return <Label color="info"> Neutral </ Label>
      default:
        return <Label color="info"> Neutral </ Label>
    }
  }

  const title = (
    <Stack direction="row" spacing={0.5}>
      <Iconify icon="wpf:future" sx={{color: theme.palette.primary.main}} width={30}/>
      <Typography variant="h6">{futurePerformance.question.question}</Typography>
    </Stack>
  );

  return (
    <Box sx={{mt: 3}}>

      <ExpandableTitle title={title}>
        {futurePerformance.answer.statements.sort((a, b) => {
          const sentimentOrder = {"positive": 1, "neutral": 2, "negative": 3};
          return sentimentOrder[a.outlook] - sentimentOrder[b.outlook];
        }).map((statement, index) => {
          // Check if the quote is found in the document paragraphs
          const isQuoteInDocument = paragraphs.some(paragraph => paragraph.toLowerCase().includes(statement.quote_from_document?.slice(0, -1).toLowerCase()));

          return (
            <React.Fragment key={index}>
              <Typography variant="body2" fontWeight={700} sx={{mt: 1}}>{statement.metric}:</Typography>
              <Stack direction="row" spacing={0.5} sx={{ml: 1}}>
                <Box sx={{minWidth: "63px"}}>
                  {getLabelElement(statement.outlook)}
                </Box>
                <Typography variant="body2">{statement.statement}</Typography>
                <EarningsQuote
                  quote={statement.quote_from_document}
                  onQuoteClick={onQuoteClick}
                  isQuoteInDocument={isQuoteInDocument} // Pass the flag here
                />
              </Stack>
            </React.Fragment>
          );
        })}
      </ExpandableTitle>
    </Box>
  );
};
