import React, {useEffect, useState} from 'react';
// @mui
import {LinearProgress, TableCell, TableRow} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useTheme} from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import useMediaQuery from "@mui/material/useMediaQuery";

import {useAuthContext} from "../../auth/hooks";
import Iconify from "../iconify";
import CustomTable from "../utility_components/custom-table";
import CompanyInsiderTradingCard from "./company-insider-trading-card";
import {fShortenNumber} from "../../utils/format-number";
import axios, {endpoints} from "../../utils/axios";
import {blurredItemPointer} from "../../common/styles";
import {BecomePremiumToAccessDialog} from "../utility_components/become-premium-to-access-dialog";
import {getUserType, UserTypes} from "../company/utils";

export interface InsiderTradingType {
  trades: {
    symbol: string,
    filingDate: string,
    transactionDate: string,
    reportingCik: string,
    transactionType: string,
    securitiesOwned: number,
    companyCik: string,
    reportingName: string,
    typeOfOwner: string,
    acquisitionOrDisposition: string,
    formType: string,
    securitiesTransacted: number,
    price: number,
    securityName: string,
    link: string
  }[]
  value_bought: number,
  shares_bought: number,
  shares_bought_percentage: number,
  value_sold: number,
  shares_sold: number,
  shares_sold_percentage: number,
  unique_insiders_buying: number,
  unique_insiders_selling: number,
  buy_trades: number,
  sell_trades: number
}

export default function CompanyInsiderTrading({ticker}: {
  ticker: string,
}) {
  const theme = useTheme();
  const {authenticated, user} = useAuthContext();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  const defaultInsiderTrading: InsiderTradingType = {
    trades: Array.from({length: 10}, (_, index) => ({
      symbol: "AAPL",
      filingDate: `2021-08-${String(index + 1).padStart(2, '0')}`,
      transactionDate: `2021-07-${String(index + 1).padStart(2, '0')}`,
      reportingCik: `1234${index}`,
      transactionType: index % 2 === 0 ? "P-Purchase" : "S-Sale",
      securitiesOwned: 123 + index,
      companyCik: `1234${index}`,
      reportingName: `John Doe ${index}`,
      typeOfOwner: "Director",
      acquisitionOrDisposition: "A",
      formType: "4",
      securitiesTransacted: 123 + index,
      price: 123 + index,
      securityName: "Apple Inc",
      link: `https://www.sec.gov/Archives/edgar/data/320193/000120919121029123/0001209191-21-029123-index.htm`
    })),
    value_bought: 4231234,
    shares_bought: 321,
    shares_bought_percentage: 0.1,
    value_sold: 12332,
    shares_sold: 123,
    shares_sold_percentage: 0.1,
    unique_insiders_buying: 3,
    unique_insiders_selling: 24,
    buy_trades: 21,
    sell_trades: 21
  }

  const [insiderTrading, setInsiderTrading] = useState<InsiderTradingType | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [premiumDialogOpen, setPremiumDialogOpen] = React.useState(false);


  const userType = getUserType(user, ticker);
  const blurred = (!authenticated || (userType === UserTypes.PremiumNoCredits || userType === UserTypes.FreeNoCredits));

  useEffect(() => {
    const getInsiderTrading = async () => {
      if (blurred) {
        return;
      }
      try {
        setLoading(true);
        const result = await axios.get<any>(`${endpoints.companies.insiderTrading}/${ticker}`);
        setInsiderTrading(result.data);
      } catch (error) {
        console.error('Error fetching insider trading data:', error);
      }
      setLoading(false);
    }

    getInsiderTrading();
  }, [ticker, blurred]);

  if (loading) {
    return <LinearProgress/>;
  }


  const renderTransactionType = (transactionType: string) => {
    if (transactionType === 'P-Purchase') {
      return <Typography variant="body2" sx={{color: 'primary.main', fontWeight: "bold"}}>Purchase</Typography>;
    }
    if (transactionType === 'S-Sale') {
      return <Typography variant="body2" sx={{color: 'error.main', fontWeight: "bold"}}>Sale</Typography>;
    }
    return <Typography variant="body2"
                       sx={{color: 'text.secondary', fontWeight: "bold"}}>{transactionType}</Typography>;
  }

  const renderTradeValue = (value: number, transactionType: string) => {
    if (transactionType === 'P-Purchase') {
      return <Typography variant="body2"
                         sx={{color: 'primary.main'}}>${Math.round(value).toLocaleString()}</Typography>;
    }
    if (transactionType === 'S-Sale') {
      return <Typography variant="body2" sx={{
        color: 'error.main',
        fontWeight: "bold"
      }}>${Math.round(value).toLocaleString()}</Typography>;
    }
    return <Typography variant="body2" sx={{
      color: 'text.secondary',
      fontWeight: "bold"
    }}>${Math.round(value).toLocaleString()}</Typography>;
  }

  const renderNameAndPosition = (name: string, position: string) => {
    // make lowercase then capitalize first letter of each word
    const capitalizedName = name.toLowerCase().split(' ').map((s) => s.charAt(0).toUpperCase() + s.substring(1)).join(' ');
    return (
      <Stack direction="column" spacing={0.2}>
        <Typography variant="body2" sx={{color: 'text.primary'}}>{capitalizedName}</Typography>
        <Typography variant="caption" sx={{color: 'text.secondary'}}>{position}</Typography>
      </Stack>
    )
  }

  const renderDate = (date: string) => <Typography variant="body2"
                                                   sx={{color: 'text.secondary'}}>{new Date(date).toLocaleDateString('en-GB', {
    day: '2-digit',
    month: 'short',
    year: 'numeric'
  })}</Typography>

  const headers = [
    'Filing Date',
    'Transaction Date',
    'Type',
    'Reporting Name',
    'Securities Transacted',
    'Price',
    "Value",
    "Filing"
  ];

  const prepareRawData = (insideTradingData: InsiderTradingType) => insideTradingData.trades
    // show transactions from newest to oldest
    .sort((a, b) => new Date(b.transactionDate).getTime() - new Date(a.transactionDate).getTime())
    .map((trade, index) => ({
      "Filing Date": trade.filingDate,
      "Transaction Date": trade.transactionDate,
      "Type": trade.transactionType,
      "Reporting Name": trade.reportingName,
      "Type of Owner": trade.typeOfOwner,
      "Securities Transacted": trade.securitiesTransacted,
      "Price": trade.price,
      "Value": trade.securitiesTransacted * trade.price,
      "Link": trade.link,
      index
    }))

  const renderRow = (row: any, index: number) => (
    <TableRow key={index} sx={{maxHeight: "50px", whiteSpace: 'nowrap'}}>
      <TableCell align="center">{renderDate(row["Filing Date"])}</TableCell>
      <TableCell align="center">{renderDate(row["Transaction Date"])}</TableCell>
      <TableCell align="center">{renderTransactionType(row.Type)}</TableCell>
      <TableCell align="center">{renderNameAndPosition(row["Reporting Name"], row["Type of Owner"])}</TableCell>
      <TableCell align="center">{Math.round(row["Securities Transacted"]).toLocaleString()}</TableCell>
      <TableCell align="center">${row.Price}</TableCell>
      <TableCell align="center">{renderTradeValue(row.Value, row.Type)}</TableCell>
      <TableCell align="center">
        <a href={row.Link} target="_blank" rel="noreferrer" style={{color: "inherit"}}>
          <Iconify icon="eva:external-link-fill" width={16}/>
        </a>
      </TableCell>
    </TableRow>
  );

  const renderBlurredRow = (row: any, index: number) => (
    <TableRow key={index} sx={{maxHeight: "50px", whiteSpace: 'nowrap'}}>
      <TableCell align="center" sx={blurred ? blurredItemPointer : {}}
                 onClick={blurred ? () => setPremiumDialogOpen(true) : undefined}>{renderDate(row["Filing Date"])}</TableCell>
      <TableCell align="center" sx={blurred ? blurredItemPointer : {}}
                 onClick={blurred ? () => setPremiumDialogOpen(true) : undefined}>{renderDate(row["Transaction Date"])}</TableCell>
      <TableCell align="center" sx={blurred ? blurredItemPointer : {}}
                 onClick={blurred ? () => setPremiumDialogOpen(true) : undefined}>{renderTransactionType(row.Type)}</TableCell>
      <TableCell align="center" sx={blurred ? blurredItemPointer : {}}
                 onClick={blurred ? () => setPremiumDialogOpen(true) : undefined}>{renderNameAndPosition(row["Reporting Name"], row["Type of Owner"])}</TableCell>
      <TableCell align="center" sx={blurred ? blurredItemPointer : {}}
                 onClick={blurred ? () => setPremiumDialogOpen(true) : undefined}>{Math.round(row["Securities Transacted"]).toLocaleString()}</TableCell>
      <TableCell align="center" sx={blurred ? blurredItemPointer : {}}
                 onClick={blurred ? () => setPremiumDialogOpen(true) : undefined}>${row.Price}</TableCell>
      <TableCell align="center" sx={blurred ? blurredItemPointer : {}}
                 onClick={blurred ? () => setPremiumDialogOpen(true) : undefined}>{renderTradeValue(row.Value, row.Type)}</TableCell>
      <TableCell align="center" sx={blurred ? blurredItemPointer : {}}
                 onClick={blurred ? () => setPremiumDialogOpen(true) : undefined}>
        <Iconify icon="eva:external-link-fill" width={16}/>
      </TableCell>
    </TableRow>
  );


  if (blurred) {
    return (
      <Stack direction="column" spacing={2}>
        <Typography variant="body2">When insiders buy shares, it often signals confidence in the company’s future. On
          the
          other hand, insider selling does not always signal lack of confidence. It could be due to personal reasons
          (e.g., diversification, tax obligations) rather than concerns about the company.</Typography>
        <Typography variant="h6" sx={{color: 'text.primary', fontWeight: "bold"}}>Last 90 days overview</Typography>
        <Stack direction={isMobile ? "column" : "row"}
               spacing={1} alignItems="center" justifyContent="center">
          <CompanyInsiderTradingCard ticker={ticker} color="primary.main" icon="lineicons:trend-up-1"
                                     title1="Value Bought" value1={defaultInsiderTrading.value_bought} value1_prefix="$"
                                     help1="Total value of shares (price * share count) bought by insiders in the last 90 days"
                                     title2="Total Shares" value2={defaultInsiderTrading.shares_bought}
                                     help2="Total number of shares bought by insiders in the last 90 days"
                                     subtitle2={`${fShortenNumber(defaultInsiderTrading.shares_bought_percentage)}% of total`}
                                     blurred/>
          <CompanyInsiderTradingCard ticker={ticker} color="primary.main" icon="fa6-solid:user-tie"
                                     title1="Buy Trades" value1={defaultInsiderTrading.buy_trades}
                                     help1="Total number of buy trades made by insiders in the last 90 days"
                                     title2="Insiders Buying"
                                     help2="Total number of unique insiders who bought shares in the last 90 days"
                                     value2={defaultInsiderTrading.unique_insiders_buying} subtitle2="unique insiders"
                                     blurred/>
          <CompanyInsiderTradingCard ticker={ticker} color="error.main" icon="lineicons:trend-down-1"
                                     title1="Value Sold" value1={defaultInsiderTrading.value_sold} value1_prefix="$"
                                     help1="Total value of shares (price * share count) sold by insiders in the last 90 days"
                                     title2="Total Shares" value2={defaultInsiderTrading.shares_sold}
                                     help2="Total number of shares sold by insiders in the last 90 days"
                                     subtitle2={`${fShortenNumber(defaultInsiderTrading.shares_sold_percentage)}`}
                                     blurred/>
          <CompanyInsiderTradingCard ticker={ticker} color="error.main" icon="fa6-solid:user-tie"
                                     title1="Sell Trades" value1={defaultInsiderTrading.sell_trades}
                                     help1="Total number of sell trades made by insiders in the last 90 days"
                                     title2="Insiders Selling"
                                     help2="Total number of unique insiders who sold shares in the last 90 days"
                                     value2={defaultInsiderTrading.unique_insiders_selling} subtitle2="unique insiders"
                                     blurred/>
        </Stack>
        <Typography variant="h6" sx={{color: 'text.primary', fontWeight: "bold"}}>Last year trades</Typography>
        <CustomTable headers={headers} rawData={prepareRawData(defaultInsiderTrading)}
                     renderRow={(row: any) => renderBlurredRow(row, row.index)}/>
        <BecomePremiumToAccessDialog open={premiumDialogOpen} onClose={() => setPremiumDialogOpen(false)}
                                     ticker={ticker}/>
      </Stack>
    );
  }

  if (!insiderTrading || !insiderTrading.trades) {
    return (
      <Stack sx={{mb: 3, height: '100%'}} spacing={1} direction="column" alignItems="center" justifyContent="center">
        <Stack direction="row" spacing={0.5} alignItems="center">
          <Iconify width={28} icon='icon-park-twotone:attention' sx={{color: 'error.main'}}/>
          <Typography variant="h6" sx={{color: 'text.primary'}}>
            Insider Trading
          </Typography>
        </Stack>
        <Typography variant="body2" sx={{color: 'text.secondary'}}>
          No insider trading data available.
        </Typography>
      </Stack>
    );
  }

  const sharesBoughtPercentageStr = `${fShortenNumber(insiderTrading.shares_bought_percentage)}% of total`;
  const sharesSoldPercentageStr = `${fShortenNumber(insiderTrading.shares_sold_percentage)}% of total`;
  return (
    <Stack direction="column" spacing={2}>
      <Typography variant="body2">When insiders buy shares, it often signals confidence in the company’s future. On
        the
        other hand, insider selling does not always signal lack of confidence. It could be due to personal reasons
        (e.g., diversification, tax obligations) rather than concerns about the company.</Typography>
      <Typography variant="h6" sx={{color: 'text.primary', fontWeight: "bold"}}>Last 90 days overview</Typography>
      <Stack direction={isMobile ? "column" : "row"}
             spacing={1} alignItems="center" justifyContent="center">
        <CompanyInsiderTradingCard ticker={ticker} color="primary.main" icon="lineicons:trend-up-1"
                                   title1="Value Bought" value1={insiderTrading.value_bought} value1_prefix="$"
                                   help1="Total value of shares (price * share count) bought by insiders in the last 90 days"
                                   title2="Total Shares" value2={insiderTrading.shares_bought}
                                   help2="Total number of shares bought by insiders in the last 90 days"
                                   subtitle2={sharesBoughtPercentageStr}/>
        <CompanyInsiderTradingCard ticker={ticker} color="primary.main" icon="fa6-solid:user-tie"
                                   title1="Buy Trades" value1={insiderTrading.buy_trades}
                                   help1="Total number of buy trades made by insiders in the last 90 days"
                                   title2="Insiders Buying"
                                   help2="Total number of unique insiders who bought shares in the last 90 days"
                                   value2={insiderTrading.unique_insiders_buying} subtitle2="unique insiders"
        />
        <CompanyInsiderTradingCard ticker={ticker} color="error.main" icon="lineicons:trend-down-1"
                                   title1="Value Sold" value1={insiderTrading.value_sold} value1_prefix="$"
                                   help1="Total value of shares (price * share count) sold by insiders in the last 90 days"
                                   title2="Total Shares" value2={insiderTrading.shares_sold}
                                   help2="Total number of shares sold by insiders in the last 90 days"
                                   subtitle2={sharesSoldPercentageStr}/>
        <CompanyInsiderTradingCard ticker={ticker} color="error.main" icon="fa6-solid:user-tie"
                                   title1="Sell Trades" value1={insiderTrading.sell_trades}
                                   help1="Total number of sell trades made by insiders in the last 90 days"
                                   title2="Insiders Selling"
                                   help2="Total number of unique insiders who sold shares in the last 90 days"
                                   value2={insiderTrading.unique_insiders_selling} subtitle2="unique insiders"
        />
      </Stack>
      <Typography variant="h6" sx={{color: 'text.primary', fontWeight: "bold"}}>Last year trades</Typography>
      <CustomTable headers={headers} rawData={prepareRawData(insiderTrading)}
                   renderRow={(row: any) => renderRow(row, row.index)}/>
      <BecomePremiumToAccessDialog open={premiumDialogOpen} onClose={() => setPremiumDialogOpen(false)}
                                   ticker={ticker}/>
    </Stack>);
}
