import React from 'react';
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {useTheme} from "@mui/material/styles";
import Iconify from '../iconify';
import {CapitalAllocationType, RisksType} from './types';
import EarningsQuote from "./earnings_quote";
import ExpandableTitle from "./expandable_title";
import Label from "../label"; // Assuming types are extracted into a common file

export default function RenderCapitalAllocation({capitalAllocation, paragraphs, onQuoteClick}: {
  capitalAllocation: CapitalAllocationType
  paragraphs: string[]
  onQuoteClick: (quote: string) => void
}) {
  const theme = useTheme();
    const getLabelElement = (type: string) => {
    switch (type) {
      case "dividend":
        return <Label color="info"> Dividend </Label>
      case "buyback":
        return <Label color="info"> Buyback </ Label>
      case "debt":
        return <Label color="error"> Debt </ Label>
      default:
        return <Label color="warning"> Medium </ Label>
    }
  }
  const title = (
    <Stack direction="row" spacing={0.5}>
      <Iconify icon="carbon:strategy-play" sx={{color: theme.palette.primary.main}} width={30}/>
      <Typography variant="h6">{capitalAllocation.question.question}</Typography>
    </Stack>
  );
  return (
    <Box sx={{mt: 3}}>
      <ExpandableTitle title={title}>
        {capitalAllocation.answer.allocations.map((allocation, index) => {
          // Check if the quote is found in the document paragraphs
          const isQuoteInDocument = paragraphs.some(paragraph => paragraph.toLowerCase().includes(allocation.quote_from_document?.slice(0, -1).toLowerCase()));

          return (
            <React.Fragment key={index}>
              <Stack direction="row" spacing={0.5} sx={{ml: 1, mt: 1}}>
                <Box sx={{minWidth: "63px"}}>
                  {getLabelElement(allocation.type)}
                </Box>
                <Typography variant="body2">{allocation.details}</Typography>
                <EarningsQuote quote={allocation.quote_from_document} onQuoteClick={onQuoteClick}
                               isQuoteInDocument={isQuoteInDocument}/>
              </Stack>
            </React.Fragment>
          );
        })}
      </ExpandableTitle>
    </Box>
  );
};
