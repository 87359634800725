import React from "react";
import {Box, List, ListItem, ListItemButton, ListItemText} from "@mui/material";
import {alpha, useTheme} from "@mui/material/styles";

type NavigationItem = {
  title: string;
  path: string;
};

type NavigationProps = {
  currentSectionId: string;
  setCurrentSectionId: (id: string) => void;
  navItems: { subheader: string; items: NavigationItem[] }[];

};

const CompanyNavigation: React.FC<NavigationProps> = ({currentSectionId, setCurrentSectionId, navItems}) => {
  const theme = useTheme();

  const activeStyles = {
    root: {
      borderRight: `4px solid`,
      fontWeight: theme.typography.fontWeightBold,
      backgroundColor: 'transparent !important',

    },
  };

  return (
    <Box>
      <List>
        {navItems[0].items.map((item: NavigationItem) => (
          <ListItem key={item.path} disablePadding sx={{
            width: '100%',
            '& .MuiListItemButton-root': {fontWeight: 100},
          }}>
            <ListItemButton
              onClick={() => setCurrentSectionId(item.path.substring(1))}
              selected={currentSectionId === item.path.substring(1)} // Match current section by ID
              sx={{
                textAlign: 'right',
                color: theme.palette.text.secondary,
                ...(currentSectionId === item.path.substring(1) && {
                  ...activeStyles.root,
                }),
              }}
            >
              <ListItemText
                primary={item.title}
                sx={{
                  '& .MuiTypography-root': {
                    fontWeight:
                      currentSectionId === item.path.substring(1)
                        ? theme.typography.fontWeightBold
                        : theme.typography.fontWeightRegular,
                  }
                }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default CompanyNavigation;
