// @mui
import React from "react";
import {Box, Card, Grid, CardHeader} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import ScoreProgress from "../utility_components/score-progress";
import {pbExplanation, peExplanation, pegExplanation, pfcfExplanation, psExplanation} from "../../common/texts";
import LabelWithPopover from "../utility_components/label-with-popover";
import {scoreToText} from "../../utils/constants";

export interface RelativeValuationRatioData {
  value: number,
  vh_score: number,
  relative_score: number,
  relative_percentage: number,
  total_companies: number
}

export interface RelativeValuationData {
  price_to_earnings: RelativeValuationRatioData,
  price_to_book: RelativeValuationRatioData,
  price_to_free_cashflow: RelativeValuationRatioData,
  ev_to_ebitda: RelativeValuationRatioData,
  peg: RelativeValuationRatioData,
}

export default function CompanyValuationRatiosScoring({sector, relativeValuation}: {
  sector: string | undefined,
  relativeValuation: RelativeValuationData
}) {
  const theme = useTheme();

  // add label and explanation for each ratio in relativeValuation
  const data = [
    {
      label: "P/E Ratio",
      explanation: peExplanation,
      value: parseFloat(relativeValuation.price_to_earnings.value.toFixed(1)),
      vh_score: parseFloat(relativeValuation.price_to_earnings.vh_score.toFixed(1)),
      relative_score: parseFloat(relativeValuation.price_to_earnings.relative_score.toFixed(1)),
      relative_percentage: parseFloat(relativeValuation.price_to_earnings.relative_percentage.toFixed(1)),
      total_companies: parseFloat(relativeValuation.price_to_earnings.total_companies.toFixed(1))
    },
    {
      label: "P/B Ratio",
      explanation: pbExplanation,
      value: parseFloat(relativeValuation.price_to_book.value.toFixed(1)),
      vh_score: parseFloat(relativeValuation.price_to_book.vh_score.toFixed(1)),
      relative_score: parseFloat(relativeValuation.price_to_book.relative_score.toFixed(1)),
      relative_percentage: parseFloat(relativeValuation.price_to_book.relative_percentage.toFixed(1)),
      total_companies: parseFloat(relativeValuation.price_to_book.total_companies.toFixed(1))
    },
    {
      label: "P/FCF Ratio",
      explanation: psExplanation,
      value: parseFloat(relativeValuation.price_to_free_cashflow.value.toFixed(1)),
      vh_score: parseFloat(relativeValuation.price_to_free_cashflow.vh_score.toFixed(1)),
      relative_score: parseFloat(relativeValuation.price_to_free_cashflow.relative_score.toFixed(1)),
      relative_percentage: parseFloat(relativeValuation.price_to_free_cashflow.relative_percentage.toFixed(1)),
      total_companies: parseFloat(relativeValuation.price_to_free_cashflow.total_companies.toFixed(1))
    },
    {
      label: "EV/EBITDA",
      explanation: pfcfExplanation,
      value: parseFloat(relativeValuation.ev_to_ebitda.value.toFixed(1)),
      vh_score: parseFloat(relativeValuation.ev_to_ebitda.vh_score.toFixed(1)),
      relative_score: parseFloat(relativeValuation.ev_to_ebitda.relative_score.toFixed(1)),
      relative_percentage: parseFloat(relativeValuation.ev_to_ebitda.relative_percentage.toFixed(1)),
      total_companies: parseFloat(relativeValuation.ev_to_ebitda.total_companies.toFixed(1))
    },
    {
      label: "PEG Ratio",
      explanation: pegExplanation,
      value: parseFloat(relativeValuation.peg.value.toFixed(1)),
      vh_score: parseFloat(relativeValuation.peg.vh_score.toFixed(1)),
      relative_score: parseFloat(relativeValuation.peg.relative_score.toFixed(1)),
      relative_percentage: parseFloat(relativeValuation.peg.relative_percentage.toFixed(1)),
      total_companies: parseFloat(relativeValuation.peg.total_companies.toFixed(1))
    },
  ]

  const getRelativeScorePopoverContent = (valuationData: any) => {
    const color = valuationData.relative_percentage > 50 ? theme.palette.success.dark : theme.palette.error.main;
    if (valuationData.value < 0) {
      return (
        <Typography variant="body2">
          A negative <span style={{fontWeight: 'bold'}}>{valuationData.label}</span> value of <span
          style={{color}}>{valuationData.value}</span> cannot be compared to other companies.

        </Typography>
      );
    }

    if (valuationData.relative_percentage > 50) {
      return (
        <>
          <Typography variant="body2" fontWeight="bold">
            Score relative to companies in the same sector.
          </Typography>
          <br/>
          <Typography variant="body2">
            Ranked <span
            style={{
              color,
              fontWeight: 'bold'
            }}>better than {valuationData.relative_percentage.toFixed(1)}%</span> of <span
            style={{fontWeight: 'bold'}}>{valuationData.total_companies}</span> companies {sector ?
            <span>in the <span style={{fontWeight: 'bold'}}>{sector}</span> sector.</span> : '.'}
          </Typography>
          <br/>
          <Typography variant="caption"> <i>Disclaimer:</i> Only companies with meaningful {valuationData.label} are
            considered. </Typography>
        </>
      );
    }
    return (
      <>
        <Typography variant="body2" fontWeight="bold">
          Score relative to companies in the same sector.
        </Typography>
        <br/>
        <Typography variant="body2">
          Ranked <span
          style={{
            color,
            fontWeight: 'bold'
          }}>worse than {(100 - valuationData.relative_percentage).toFixed(1)}%</span> of <span
          style={{fontWeight: 'bold'}}>{valuationData.total_companies}</span> companies {sector ?
          <span>in the <span style={{fontWeight: 'bold'}}>{sector}</span> sector.</span> : '.'}
        </Typography>
        <br/>
        <Typography variant="caption"> <i>Disclaimer:</i> Only companies with meaningful {valuationData.label} are
          considered.
        </Typography>
      </>
    );
  }

  const getVHScorePopoverContent = (valuationData: any) => (
    <>
      <Typography variant="body2">
        Score as per ValueHunter{`'`}s proprietary algorithm used into the overall score of the company.
      </Typography>
      <br/>
      <Typography variant="body2">
        <span style={{fontWeight: 'bold'}}>{valuationData.vh_score}</span>/5 - <span
        style={{fontWeight: 'bold'}}>{scoreToText[Math.round(valuationData.vh_score)]}</span>
      </Typography>
    </>
  )

  const renderContent = () => (
    <Box sx={{mt: 1, p: 2}}>
      <Box>
        <Grid container spacing={1}>
          <Grid item xs={3}/>
          <Grid item xs={3}>
            <Typography variant="body2"
                        sx={{color: 'text.secondary', textAlign: "center"}}>
              Value
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body2"
                        sx={{color: 'text.secondary', textAlign: "center"}}>
              vs Sector
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body2"
                        sx={{color: 'text.secondary', textAlign: "center"}}>
              VH Score
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Grid container spacing={1}>
        <Grid item xs={3}>
          <Stack spacing={1}>
          {data.map((ratio, ratioIndex) => (
            <LabelWithPopover labelText={ratio.label} popoverContent={ratio.explanation}/>
          ))}
          </Stack>
        </Grid>
        <Grid item xs={3}>
          <Stack spacing={1}>
          {data.map((ratio, ratioIndex) => (
            <Typography key={`ratio-value-${ratioIndex}`}
                        variant="body2" textAlign="center" fontWeight="bold">
              {ratio.value}
            </Typography>
          ))}
          </Stack>
        </Grid>
        <Grid item xs={3}>
          <Stack spacing={1}>
          {data.map((ratio, ratioIndex) => (

            <Box key={`ratio-score-${ratioIndex}`} component="span">
              <ScoreProgress
                scoreValue={ratio.relative_score}
                popoverContent={getRelativeScorePopoverContent(ratio)}
              />
            </Box>
          ))}
          </Stack>
        </Grid>
        <Grid item xs={3}>
          <Stack spacing={1}>
          {data.map((ratio, ratioIndex) => (
            <Box key={`ratio-vh-score-${ratioIndex}`} component="span">
              <ScoreProgress
                scoreValue={ratio.vh_score}
                popoverContent={getVHScorePopoverContent(ratio)}
              />
            </Box>
          ))}
          </Stack>
        </Grid>
      </Grid>
    </Box>
  )

  return (
        <Card sx={{height: '392px'}}>
          <CardHeader
            title="Valuation Ratios"
            subheader={
              <Typography
                variant="body2"
                component="span"
                color="text.secondary"
              >
                The main valuation ratios ranked to other companies in the same sector and the ValueHunter score for each of them.
              </Typography>
            }
          />
          {renderContent()}
        </Card>
  );
}
