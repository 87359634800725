import {Alert} from "@mui/material";
import React from "react";
import Typography from "@mui/material/Typography";


export default function OldDataWarning({dataDate , showIfOlderThanMonths}: { dataDate: string, showIfOlderThanMonths: number }) {
    const today = new Date();
    // dataDate is newer than showIfOlderThanMonths return null
    const dataDateObj = new Date(dataDate);
    const diff = today.getTime() - dataDateObj.getTime();
    const diffMonths = diff / (1000 * 60 * 60 * 24 * 30);
    if (diffMonths < showIfOlderThanMonths) {
        return null;
    }

    return (
        <Alert severity="warning" sx={{mb: 2}}>
            <Typography variant="body2">
                Looks like the data that we have for this section is not up to date. Please contact
                us at <strong>contact@valuehunter.net</strong> so that we can fix it. Thanks!
            </Typography>
        </Alert>
    );
}
