// @mui
import React from "react";
import {Box, Card, Grid} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import {useNavigate} from "react-router-dom";

import Chart, {useChart} from 'src/components/chart';
import Iconify from "../iconify";
import {paths} from "../../routes/paths";

interface CompanyDocumentsProps {
  ticker: string;
  dcfData: {
    dcf_price: number;
    stock_price: number;
  }
  currencySymbol: string;
}

export default function CompanyValuationIntrinsicChart({
                                                         ticker,
                                                         dcfData,
                                                         currencySymbol
                                                       }: CompanyDocumentsProps) {
  const theme = useTheme();
  const primaryDark = theme.palette.grey[500]
  const errorColor = theme.palette.error.light;

  const currentPrice = dcfData.stock_price;
  const fairValue = dcfData.dcf_price;
  const percentDifference = Math.abs(currentPrice - fairValue) / ((currentPrice + fairValue) / 2) * 100;
  const isUnderValued = currentPrice < fairValue;
  const percentChange = `${Math.abs(percentDifference).toFixed(1)} %`;
  const navigate = useNavigate();

  const twentyPercentAboveMax = Math.max(currentPrice, fairValue) * 1.2;
  const valuationChart = useChart({
    series: [{
      name: "Price",
      data: [{
        x: "Current Price",
        y: currentPrice,
        fillColor: isUnderValued ? theme.palette.primary.main : errorColor,
      }, {
        x: "Fair Price",
        y: fairValue,
        fillColor: primaryDark
      }]
    }
    ],
    colors: [isUnderValued ? theme.palette.primary.main : errorColor, primaryDark],
    annotations: {
      yaxis: [
        {
          y: fairValue,
          y2: currentPrice,
          borderColor: '#00E396',
          fillColor: isUnderValued ? theme.palette.primary.main : errorColor,
          opacity: 0.2,
          label: {
            position: 'center',
            offsetX: 65,
            offsetY: -20,
            style: {
              fontSize: '14px',
              color: '#fff',
              padding: {
                left: 5,
                right: 5,
                top: 5,
                bottom: 5,
              },
              background: isUnderValued ? theme.palette.primary.main : errorColor,
            },
            text: `${percentChange} ${isUnderValued ? 'Undervalued' : 'Overvalued'}`
          }
        }
      ]
    },
    chart: {
      type: 'bar',
    },
    plotOptions: {
      bar: {
        borderRadius: 5,
        dataLabels: {
          position: 'top',
        },
        columnWidth: '50%',
      }
    },
    dataLabels: {
      enabled: true,
      formatter(val: number) {
        return `${currencySymbol}${val}`;
      },
      offsetY: -20,
      style: {
        fontSize: '14px',
        colors: ["#304758"]
      }
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },
    xaxis: {
      categories: ["Current Price", "Fair Price"],
      labels: {
        style: {
          colors: ["#304758", "#304758"],
          fontSize: '13px'
        }
      },
      position: 'bottom',
      crosshairs: {
        fill: {
          type: 'gradient',
          gradient: {
            colorFrom: '#D8E3F0',
            colorTo: '#BED1E6',
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.5,
          }
        }
      },
    },
    yaxis: {
      axisBorder: {
        show: true
      },
      axisTicks: {
        show: true,
      },
      labels: {
        show: true,
        style: {
          fontSize: '12px'
        },
        formatter(val: number) {
          return `${currencySymbol}${Math.round(val)}`;
        }
      },
      max: twentyPercentAboveMax
    }
  });

  if (!dcfData?.dcf_price || !dcfData?.stock_price) {
    return (
      <Box sx={{mb: 3}}>
        <Typography variant="h6" sx={{color: 'text.primary'}}>
          Discounted Cash Flow
        </Typography>
        <Typography variant="body2" sx={{color: 'text.secondary'}}>
          No DCF data available.
        </Typography>
      </Box>
    );
  }
  if (dcfData?.dcf_price < 0) {
    return (
      <Stack sx={{mb: 3, height: '100%'}} spacing={1} direction="column" alignItems="center" justifyContent="center">
        <Stack direction="row" spacing={0.5} alignItems="center">
          <Iconify width={28} icon='icon-park-twotone:attention' sx={{color: 'error.main'}}/>
          <Typography variant="h6" sx={{color: 'text.primary'}}>
            Discounted Cash Flow
          </Typography>
        </Stack>
        <Typography variant="body2" sx={{color: 'text.secondary'}}>
          Negative cash flow is projected for the next 5 years. DCF valuation is not suitable for this company.
        </Typography>
      </Stack>
    );
  }

  return (
    <Box>
      <Card>
        <CardHeader
          className="card-header"
          title={
            <Stack direction="row" alignItems="center" justifyContent="space-between"
                   sx={{with: "100%"}}>
              <Typography variant="h6" sx={{color: 'text.primary', width: '100%'}}>
                Discounted Cash Flow
              </Typography>
              <Stack justifyContent="flex-end">
                <Button
                  size="small"
                  color="primary"
                  sx={{fontSize: "12px", width: 150}}
                  onClick={() => navigate(paths.informational.dcf)}
                >
                  How our DCF works?
                </Button>
              </Stack>
            </Stack>
          }
          subheader={
            <Stack direction="row" spacing={0.5} sx={{my: 2}}>
              {isUnderValued ? <Iconify
                width={20}
                icon='icon-park-twotone:check-one'
                sx={{
                  color: 'success.main',
                }}/> : <Iconify
                width={20}
                icon='icon-park-twotone:attention'
                sx={{
                  color: 'error.main',
                }}/>}


              <Typography
                variant="body2"
                component="span"
                color="text.secondary"
              >

                According to our DCF model ${ticker} is about <strong
                style={{color: isUnderValued ? theme.palette.primary.main : errorColor}}>{percentChange} {isUnderValued ? ' undervalued' : ' overvalued'}</strong>.
                {/*
              <br/>
              Click here to learn more about our DCF model.
              */}
              </Typography>
            </Stack>


          }
        />
        <Chart
          type="bar"
          dir="ltr"
          series={valuationChart.series}
          options={valuationChart}
          height={300}
        />
      </Card>
    </Box>
  );
}
