import React, {useState} from "react";
import {Box} from "@mui/material";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";

const hoverStyles = {
  transition: 'transform 0.3s ease-in-out',
  "&:hover": {
    cursor: "help",
    transform: 'scale(1.03)' // Slightly enlarge on hover
  },
}


export default function LabelWithPopover({labelText, popoverContent}: {
  labelText: string,
  popoverContent: React.ReactNode | undefined
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handlePopoverOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  return (
    // center items vertically inside the box
    <Box display="flex" alignItems="center"
         onMouseEnter={(e) => handlePopoverOpen(e)}
         onMouseLeave={handlePopoverClose}
         sx={{
           height: '22px',
           ...hoverStyles,
         }}
    >
      <Typography variant="body2" sx={{color: 'text.secondary'}}>
        {labelText}
      </Typography>
      <Popover
        id="mouse-over-popover"
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{vertical: 'top', horizontal: 'center'}}
        transformOrigin={{vertical: 'bottom', horizontal: 'center'}}
        onClose={handlePopoverClose}
        disableRestoreFocus
        disableScrollLock
        sx={{pointerEvents: 'none'}}
        slotProps={{
          paper: {style: {maxWidth: 250}},
        }}
      >
        <Typography variant="body2" sx={{p: 0.5, fontSize: 12}}>
          {popoverContent}
        </Typography>
      </Popover>
    </Box>
  )
}
