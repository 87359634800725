import React, {useCallback, useEffect, useState} from "react";
import {Box, Button, Grid, Stack, Typography, LinearProgress, Alert} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import {useNavigate, useParams} from "react-router-dom";
import axios, {endpoints} from "../../utils/axios";
import {useSearchParams} from "../../routes/hooks";
import {EarningsAnalysisType} from './types';
import RenderRevenuePerformance from './render_revenue_performance';
import RenderOperationalPerformance from "./render_operational_performance";
import EarningsDialog from "./earnings_dialog";
import RenderGoalsAndInitiatives from "./render_goals_and_initiatives";
import RenderGuidance from "./render_guidance";
import RenderRisks from "./render_risks";
import RenderCapitalAllocation from "./render_capital_allocation";
import ParticipantsDialog from "./participants_dialog";
import {ScoredCompany2} from "../company/types";
import CompanyHeader from "../documents_analysis/company_header";
import Iconify from "../iconify";
import RenderSummary from "./render_summary";
import CustomSearchbar from "../../layouts/_common/custom-searchbar/custom-searchbar";
import {useBoolean} from "../../hooks/use-boolean";
import AiProgressbarQuotes from "../utility_components/ai-progressbar-quotes";
import {paths} from "../../routes/paths";
import {customScrollbarStyles} from "../../theme/css";
import OldDataWarning from "../utility_components/old-data-warning";

export default function EarningsCallAnalysis(
  {renderHeader = true}: { renderHeader?: boolean }
) {
  const {ticker} = useParams<{ ticker?: string }>();
  const [loading, setLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [participantsDialogOpen, setParticipantsDialogOpen] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [company, setCompany] = useState<ScoredCompany2 | null>(null)
  const shareCode = useSearchParams().get('share') || '';
  const navigate = useNavigate();
  const theme = useTheme();


  const [analysis, setAnalysis] = useState<EarningsAnalysisType>({
    paragraphs: [],
    date: "",
    quarter: "",
    document: {
      questions: {
        summary: {
          question: {question: ""},
          answer: {
            summary: "",
            takeaways: []
          }
        },
        participants: {
          question: {question: ""},
          answer: {participants: []}
        },
        operational_performance: {
          question: {question: ""},
          answer: {metrics: []}
        },
        revenue_profitability: {
          question: {question: ""},
          answer: {metrics: []}
        },
        goals_initiatives: {
          question: {question: ""},
          answer: {goals: []}
        },
        future_performance: {
          question: {question: ""},
          answer: {statements: []}
        },
        risks_challenges: {
          question: {question: ""},
          answer: {risks: []}
        },
        capital_allocation: {
          question: {question: ""},
          answer: {allocations: []}
        }
      }
    }
  });

  const onQuoteClick = (quote: string) => {
    // if quote ends with a period, remove it
    if (quote.endsWith('.')) {
      quote = quote.slice(0, -1);
    }
    setSearchString(quote);
    setDialogOpen(true);
  }

  const getAnalysis = useCallback(async (tick: string) => {
    try {
      setLoading(true);
      const shareWithCode = shareCode ? `?share=${shareCode}` : '';
      const result = await axios.get<any>(`${endpoints.earnings_call_analysis}/${tick}${shareWithCode}`);
      setAnalysis(result.data);
    } catch (error) {
      console.error("Error fetching annual report analysis", error);
    } finally {
      setLoading(false);
    }
  }, [shareCode]);

  const getCompanyData = useCallback(async (tick: string) => {
    try {
      const result = await axios.get<any>(`${endpoints.companies.get}/${tick}`);
      setCompany(result.data);
    } catch (error) {
      console.error('Error fetching company data:', error);
    }
  }, []);

  useEffect(() => {
    if (ticker) {
      getAnalysis(ticker);
      getCompanyData(ticker);
    }
  }, [getAnalysis, getCompanyData, ticker]);

  const handleCompanyAddClick = async (tick: string, onDialogClose: () => void) => {
    // setTicker(tick);
    navigate(`${paths.main.document_analysis.earnings_call}/${tick}`);
  }

  const renderSearchButton = (search: ReturnType<typeof useBoolean>) => (
    <Button
      color="inherit"
      variant="outlined"
      startIcon={<Iconify icon="eva:search-outline" width={24}/>}
      onClick={() => search.onTrue()}
      sx={{borderColor: 'text.primary'}}
    >
      Select Company
    </Button>
  );

  const renderSearch = () => (
    <Stack direction="row" spacing={2} alignItems="center" justifyContent="flex-start">
      <Box id="search-button" sx={{mt: 1, position: "relative"}}>
        <CustomSearchbar
          renderSearchButton={renderSearchButton}
          handleCompanyClickCustom={handleCompanyAddClick}
          country="us"
          tickersToHide={[]}/>
      </Box>
    </Stack>
  )

  const renderCallInfo = () => (
    <Stack direction="column" spacing={0}>
      <OldDataWarning dataDate={analysis.date} showIfOlderThanMonths={6}/>
      <Stack direction="row" sx={{mt: 2}}>
        <Box sx={{width: "100%"}}>
          <Typography variant="body2" color="text.secondary">Period</Typography>
          <Typography variant="body2">Q{analysis.quarter}</Typography>
        </Box>
        <Box sx={{width: "100%"}}>
          <Typography variant="body2" color="text.secondary">Held On</Typography>
          <Typography variant="body2">{new Date(analysis.date).toLocaleDateString()}</Typography>
        </Box>
      </Stack>

      <Stack direction="row" spacing={1} sx={{mt: 2}}>
        <Button onClick={() => {
          setSearchString("");
          setDialogOpen(true);
        }}
                color="inherit"
                variant="outlined"
                sx={{width: '100%'}}
        >
          <Iconify icon="material-symbols-light:call-log-outline" sx={{mr: 0.5}}/>Transcript
        </Button>
        <Button onClick={() => setParticipantsDialogOpen(true)}
                color="inherit"
                variant="outlined"
                sx={{width: '100%'}}

        >
          <Iconify icon="mdi:account-tie" sx={{mr: 0.5}}/>
          Participants
        </Button>
      </Stack>

    </Stack>
  );

  const renderSearchHeaderAndCallInfo = () => (
    <>

      {renderSearch()}
      <Typography variant="h4" sx={{my: 2}}>Earnings Call Analysis</Typography>
      <Grid container alignItems="center" justifyContent="center" spacing={1} sx={{mb: 2}}>
        <Grid item xs={12} sm={6}>
          <CompanyHeader company={company}/>
        </Grid>
        <Grid item xs={12} sm={6}>
          {renderCallInfo()}
        </Grid>
      </Grid>
    </>
  );

  if (!ticker) {
    return (
      <Stack direction="column" spacing={1} alignItems="center" justifyContent="center" sx={{mt: 5}}>
        <Typography variant="h4">Earnings Call Analysis</Typography>
        <Typography variant="body2">Please select a company to view its earnings call analysis.</Typography>
        {renderSearch()}
      </Stack>
    );
  }

  if (loading) {
    return (
      <>
        <Box>
          <LinearProgress/>
        </Box>
        <Box sx={{mt: 5}}>
          <AiProgressbarQuotes/>
        </Box>
      </>
    )
  }

  return (
    <Box sx={{m: 2, mb: 5}}>
      {loading ? <Box>Loading...</Box> : (
        <Stack direction="column" spacing={2} alignItems={renderHeader ? "center" : "flex-start"}
               justifyContent="center">
          <Box sx={renderHeader ? {maxWidth: "800px"} : {
            maxHeight: '450px', // Adjust this value to fit exactly 5 lines of text
            overflowY: 'auto', // Enable vertical scrolling
            width: "100%",
            px: 1,
            ...customScrollbarStyles(theme)
          }}>
            {/* search button */}
            {renderHeader ? renderSearchHeaderAndCallInfo() : renderCallInfo()}

            {analysis.document.questions.summary.answer.takeaways && (
              <RenderSummary summaryWithTakeaways={analysis.document.questions.summary} onQuoteClick={onQuoteClick}
                             paragraphs={analysis.paragraphs}/>
            )}
            <RenderRevenuePerformance
              revenuePerformance={analysis.document.questions.revenue_profitability}
              onQuoteClick={onQuoteClick}
              paragraphs={analysis.paragraphs} // Pass paragraphs here
            />
            <RenderOperationalPerformance operationalPerformance={analysis.document.questions.operational_performance}
                                          onQuoteClick={onQuoteClick}
                                          paragraphs={analysis.paragraphs}/>


            {analysis.document.questions.future_performance.question && (
              <RenderGuidance futurePerformance={analysis.document.questions.future_performance}
                              onQuoteClick={onQuoteClick}
                              paragraphs={analysis.paragraphs}/>
            )}
            {analysis.document.questions.risks_challenges.question && (
              <RenderRisks risks={analysis.document.questions.risks_challenges} onQuoteClick={onQuoteClick}
                           paragraphs={analysis.paragraphs}/>
            )}
            {analysis.document.questions.goals_initiatives.question && (
              <RenderGoalsAndInitiatives goalsAndInitiatives={analysis.document.questions.goals_initiatives}
                                         onQuoteClick={onQuoteClick}
                                         paragraphs={analysis.paragraphs}/>
            )}
            {analysis.document.questions.capital_allocation.answer.allocations?.length > 0 && (
              <RenderCapitalAllocation capitalAllocation={analysis.document.questions.capital_allocation}
                                       onQuoteClick={onQuoteClick}
                                       paragraphs={analysis.paragraphs}/>
            )}
            <EarningsDialog
              dialogOpen={dialogOpen}
              setDialogOpen={(open: boolean) => setDialogOpen(open)}
              paragraphs={analysis.paragraphs}
              searchString={searchString}
              date={analysis.date}
              quarter={analysis.quarter}
            />
            <ParticipantsDialog participants={analysis.document.questions.participants}
                                dialogOpen={participantsDialogOpen} setDialogOpen={setParticipantsDialogOpen}/>
          </Box>

        </Stack>
      )}
    </Box>
  );
}
